import { PagedResponse } from '@@core/models/api.model';
import { PagedResponseWrapper } from '@@shared/common';

export enum TiaCiPolicyType {
	Daily = 'daily',
	Weekly = 'weekly',
	EveryXTestRuns = 'everyXTestRuns'
}

export enum TiaManualPolicyType {
	Weekly = 'weekly',
	Monthly = 'monthly',
	EveryXTestRuns = 'everyXTestRuns',
	EveryXMonth = 'everyXMonth'
}

export enum NoCodeChangePolicy {
	SkipAllTests = 'skipAllTests',
	RunAllTests = 'runAllTests'
}

export enum NoCodeChangePolicyCaption {
	skipAllTests = 'Skip All Tests',
	runAllTests = 'Run All Tests'
}

export enum TiaChangeReferenceBuild {
	PreviousBuild = 'previousBuild', // Default
	ReferenceBuild = 'referenceBuild'
}

export interface TiaPolicyRule {
	id: string;
	ciRules?: {
		ruleType: TiaCiPolicyType;
		iteration?: number;
	};
	manualRules?: {
		ruleType: TiaManualPolicyType;
		iteration?: number;
	};
	noCodeChangePolicy?: NoCodeChangePolicy;
	calculateChangesFrom?: TiaChangeReferenceBuild;
}

export interface TiaDefaultPolicyForm extends TiaPolicyRule {
	manualRules: {
		ruleType: TiaManualPolicyType;
		everyXMonthIteration?: number;
		everyXTestRunsIteration?: number;
		iteration?: number;
	};
}

// export interface TiaAppPolicyForm extends TiaDefaultPolicyForm {
// 	appName: string;
// 	branchName: string;
// 	testStage: string;
// }

export enum ReferenceBuildConfigurationCaption {
	PreviousBuild = 'Previous build',
	ReferenceBuild = 'Reference build'
}

export enum ReferenceBuildConfiguration {
	PreviousBuild = 'previousBuild',
	ReferenceBuild = 'referenceBuild'
}

export interface FullRunPolicy {
	type: TiaCiPolicyType | TiaManualPolicyType;
	daysBetweenFullRuns?: number; // only when type = PolicyType.EveryXTestRuns | PolicyType.everyXMonth
}

export enum FullRunScheduleType {
	ByPolicy = 'policy',
	Manual = 'manual',
}

export enum FullRunScheduleTypeCaption {
	ByPolicy = 'By Policy',
	Manual = 'Next Execution'
}

export interface RunPolicyRequestParams {
	appName: string;
	branchName: string;
	testStage: string;
	noCodeChangePolicy: NoCodeChangePolicy;
	calculateChangesFrom: ReferenceBuildConfiguration;
	ruleType: TiaCiPolicyType | TiaManualPolicyType;
	ruleMetadata: number;
	isManual: boolean;
}

export interface TiaBranchSettings {
	branchName: string;
	testSelectionEnabled: boolean;
	fullRunPolicy: FullRunPolicy;
	noCodeChangePolicy: NoCodeChangePolicy;
	calculateChangesFrom: ReferenceBuildConfiguration;
	nextFullRun: FullRunScheduleType;
}

export type TiaBranchSettingsResponse = PagedResponseWrapper<TiaBranchSettings, TiaBranchSettingsPagedResponse>;

export interface TiaBranchSettingsPagedResponse extends PagedResponse<TiaBranchSettings> {
	isManual: boolean;
	totalTiaOn: number;
}
